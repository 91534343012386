window.jQuery = $ = require('jquery')
jqueryModal = require('jquery-modal')             # https://github.com/kylefox/jquery-modal
select2 = require('select2')()
require('./core/turbolinks-google-analytics')
dropdown = require('@claviska/jquery-dropdown')
escapeHtml = require('escape-html')

charts = require('./charts')
secure = require('./secure')
dynamicAjax = require('./dynamic-ajax')

turboEach = require('./core/turbo-each')
camelcase = require('./core/camelcase')

# Slowly moving things into individual core/ components
preventDisabledAnchors = require('./core/prevent-disabled-anchors')
tooltips = require('./core/tooltips')
filterable = require('./core/filterable')
checkboxParents = require('./core/checkbox-parents')
inlineAnchorScroll = require('./core/inline-anchor-scroll')
# navigableSelect = require('./core/navigable-select') - no longer used
mobileInputScroll = require('./core/mobile-input-scroll')
hamburgers = require('./core/hamburgers')
carousel = require('./core/carousel')
lightbox = require('./core/lightbox')
modals = require('./core/modals')
sortable = require('./core/sortable')
accordian = require('./core/accordian')
toggleButtons = require('./core/toggle-buttons')
closable = require('./core/closable')

showTargetModal = modals.showTargetModal
addRelCloseToButtons = modals.addRelCloseToButtons
showRemoteModalFrom = modals.showRemoteModalFrom
refreshModals = modals.refreshModals

debug = false

refreshRadioButtons = (e) ->
  $("a.button.radio-button").each (index, element) ->
    if $(element).parent().find("input[type=radio]").is(":checked")
      $(element).addClass("selected")
    else
      $(element).removeClass("selected")

select2Template = (state) ->
  return state.text unless state.id

  extra_data = ""

  Object.keys(state.element.dataset).forEach (key) ->
    extra_data += "data-#{camelcase.camelcaseToHyphen(key)}=\"#{escapeHtml(state.element.dataset[key])}\""

  $state = $(
    if !state.element.title || state.element.text == state.element.title
      "
        <div class='select-template #{escapeHtml(state.element.className) || ""}' #{extra_data}>
          <span class='text'>#{state.element.text}</span>
        </div>
      "
    else
      "
        <div class='select-template #{escapeHtml(state.element.className) || ""}' #{extra_data}>
          <span class='text'>#{state.element.text}</span>
          <span class='title'>#{state.element.title}</span>
        </div>
      "
  )

flashTarget = () ->
  if window.location.hash
    element = $("[name='#{window.location.hash.replace("#", "")}']")
    if element
      console.log "flashing", element if debug
      element.addClass("flash")

prepareDocument = () ->
  console.log "preparing document" if debug

  $("div.radio-button, span.radio-button").each (index, element) ->
    unless $(element).data("radio-button-enabled")
      $(element).data("radio-button-enabled", true)
      radio = $(element).find("input[type=radio]")[0]
      if radio
        checked = $(radio).is(":checked")
        $(element).append """<a class="button radio-button"></a>"""

        new_button = $(element).find("a.button.radio-button")
        new_button.append($(element).find("label"))

        $(radio).on 'change', refreshRadioButtons
        $(radio).hide()

    refreshRadioButtons()

  refreshModals()

  # display modals on load
  $(".show-modal-on-load").each (index, element) ->
    showTargetModal $(element)

    # don't show it again
    $(element).removeClass("show-modal-on-load")

  $("select.dropdown").each (index, element) ->
    # important to stop turbolinks registering handlers twice
    unless $(element).data("select2-enabled")
      $(element).data("select2-enabled", true)

      # turbolinks fix
      # https://stackoverflow.com/questions/36497723/select2-with-ajax-gets-initialized-several-times-with-rails-turbolinks-events
      if $(element).data('select2') == undefined && $(element).next().hasClass('select2-container')
        $(element).next().remove()

      $(element).select2 {
        # select2's width calculation is all messed up, we can rely on CSS
        width: ""

        matcher: (params, data) ->
          # If there are no search terms, return all of the data
          if $.trim(params.term) == ''
            return data

          # `params.term` should be the term that is used for searching
          # `data.text` is the text that is displayed for the data object
          # NOTE does not use stripDiacritics
          if data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1
            return data

          if data.title.toLowerCase().indexOf(params.term.toLowerCase()) > -1
            return data

          # Return `null` if the term should not be displayed
          return null

        templateResult: select2Template

        templateSelection: select2Template
      }

  turboEach document, '.jq-dropdown-relative', (element) ->
    $(element).parent().filter("td").css({ "position": "relative", "--position-made-relative-by": "jq-dropdown" })

  $(".dropdown.insertable").each (index, element) ->
    unless $(element).data("insertable-enabled")
      $(element).data("insertable-enabled", true)

      $(element).on 'change', (e) ->
        id = $(element).val()
        store = $(element).parent().find(".insertable-contents")
        target = $(element).parent().find(".insertable-target")

        if store.length == 0 && target.length == 0
          store = $(element).parent().parent().find(".insertable-contents")
          target = $(element).parent().parent().find(".insertable-target")

        # find the template
        template = store.find("[data-insertable-id='#{escapeHtml(id)}']")
        if template.length > 0
          # check we don't already have it
          return if target.find("[data-insertable-id='#{escapeHtml(id)}']").length > 0

          copy = template.clone()
          copy.find("input[type='checkbox']").prop("checked", true)

          target.append(copy)

          # and remove the old template, so we don't try to submit multiple fields
          template.remove()

  $(".swappable").each (index, element) ->
    unless $(element).data("swappable-enabled")
      $(element).data("swappable-enabled", true)
      activeClass = $(element).attr('data-swappable-active')

      isActive = (i, e) ->
        if activeClass
          $(e).hasClass(activeClass)
        else
          i == 0

      if $(element).find("> .swap-target").length == 0
        throw new Error("Could not find any swap-targets for #{element} swappable to #{activeClass}")

      foundActive = false
      $(element).find("> .swap-target").each (i, e) ->
        if isActive(i, e)
          foundActive = true
          $(e).removeClass("hidden")
          $(e).css({display: 'block'})
        else
          $(e).addClass("hidden")
          $(e).css({display: 'none'})

        $(e).find("[data-swap-with]").each (_, button) ->
          # just stop it, turbolinks
          if $(button).attr('href') == "#"
            $(button).removeAttr('href')

          $(button).on 'click', (_event) ->
            ref = $(button).attr('data-swap-with')
            console.log "swapping to", ref if debug
            if $(ref).length > 0
              $(e).addClass("hidden")
              $(e).css({display: 'none'})
              $(ref).removeClass("hidden")
              $(ref).css({display: 'block'})

            _event.preventDefault()
            return false

      unless foundActive
        throw new Error("Did not find any active swappables for #{element} swappable to #{activeClass}")

  $("[data-on-enter]").each (index, element) ->
    unless $(element).data("on-enter-enabled")
      $(element).data("on-enter-enabled", true)

      $(element).on 'keypress', (e) ->
        if e.keyCode == 13
          ref = $(element).attr("data-on-enter")
          $(ref).click()
          return false

  turboEach document, ".columns.columns-2-1 .column.image", (element) ->
    # We will want to display this image _after_ the next column, not before it
    # (as it would render to desktop). Otherwise we would have to copy/paste the
    # image content twice, ew.
    unless $(element).is(".mobile-show, .mobile-hide")
      console.log "moving column image", element if debug

      extra = $(element).clone()
      extra.addClass('mobile-show')
      $(element).parent().append(extra)

      console.log "added column image", extra if debug

      $(element).addClass('mobile-hide')

  dynamicAjax.apply()
  checkboxParents.apply(document)
  filterable.apply(document)
  hamburgers.apply(document)
  inlineAnchorScroll.apply(document)
  # navigableSelect.apply(document)
  preventDisabledAnchors.apply(document)
  sortable.apply(document)
  tooltips.apply(document) # Must be after sortable, in case we have a tooltip in the table
  carousel.apply(document)
  lightbox.apply(document)
  accordian.apply(document)
  toggleButtons.apply(document)
  mobileInputScroll.apply(document)
  closable.apply(document)

  window.BundleExecutedAt = Date.now()

  flashTarget()

resetBundleExecutedAt = () ->
  # So that we can keep track of when the turbolinks document is actually
  # ready to be interacted with, particularly for CI builds
  window.BundleExecutedAt = null

# TODO refactor out
telemetryEvent = (label, e) ->
  if typeof Rollbar != "undefined"
    data = {
      event: label,
      url:   document.location.href,
    }

    Rollbar.captureEvent(data, 'info')

telemetryBeforeVisit = (e) ->
  telemetryEvent('turbolinks:before-visit', e)

telemetryLoad = (e) ->
  telemetryEvent('turbolinks:load', e)

$(document).on 'turbolinks:before-visit', resetBundleExecutedAt
$(document).on 'turbolinks:load', prepareDocument
$(document).on 'openclerk:document-updated', prepareDocument
$(document).on 'ready', prepareDocument
$(window).on 'load', prepareDocument
$(document).on 'openclerk:refresh-modals', refreshModals

if typeof rollbar != "undefined"
  $(document).on 'turbolinks:before-visit', telemetryBeforeVisit
  $(document).on 'turbolinks:load', telemetryLoad

window.BundleLoadedAt = Date.now()
window.ReloadBundle = () ->
  resetBundleExecutedAt()
  prepareDocument()

