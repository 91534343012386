$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

mobile_width = 600 # px
top_padding = 10 # px
animation_delay = 250 # ms
selector_key = "mobile-input-scroll-enabled"

screenWidth = () ->
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

screenHeight = () ->
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

currentOffsetY = () ->
  window.pageYOffset

scrollIfOutOfMobileView = (element, skipElementsAlreadyVisible) ->
  screen_width = screenWidth()
  screen_height = screenHeight()
  element_top = $(element).offset().top
  viewport_top = currentOffsetY()

  if screen_width <= mobile_width
    if skipElementsAlreadyVisible
      # assume virtual keyboards take up 50% of the height
      if element_top >= viewport_top && element_top <= viewport_top + (screen_height * 0.5)
        console.log "Ignoring scroll: element should already be on page" if debug
        return

    console.log "Scrolling to", element, "because viewport width of", screen_width, "is less than mobile width of", mobile_width if debug

    $('html, body').animate({
        scrollTop: element_top - top_padding
    }, animation_delay)

apply = (parent) ->
  turboEach document, 'select.dropdown', selector_key, (element) ->
    $(element).on 'select2:opening', (e) ->
      scrollIfOutOfMobileView element, false # assume the select2 dropdown needs the full screen height

  turboEach document, 'input[type=text],input[type=password],textarea', selector_key, (element) ->
    $(element).on 'focus', (e) ->
      scrollIfOutOfMobileView element, true

module.exports = {
  apply: apply,
}
