$ = require('jquery')
accounting = require('accounting')

turboEach = require('./turbo-each')
tooltips = require('./tooltips')

debug = false

lastItemSelected = null

selectAll = (parent, event) ->
  checkbox = $(event.target)

  console.log "Selecting all on", checkbox if debug
  $(parent).find(":checkbox:not(.select-all)").prop "checked", checkbox.is(":checked")

  updateStates(parent)

selectItem = (parent, event) ->
  checkbox = $(event.target)
  children = $(parent).find(":checkbox:not(.select-all)")
  newState = $(checkbox).is(":checked")

  if event.shiftKey && lastItemSelected
    console.log "SHIFT is held; selecting all from", lastItemSelected if debug

    # Find the index of this checkbox, and the last selected checkbox
    thisIndex = children.index(checkbox)
    lastIndex = children.index(lastItemSelected)

    if thisIndex && lastIndex
      console.log "Selecting from", thisIndex, "to", lastIndex if debug

      children.each (index, child) ->
        if thisIndex < lastIndex && index >= thisIndex and index <= lastIndex
          $(child).prop "checked", newState
        else if thisIndex > lastIndex && index >= lastIndex and index <= thisIndex
          $(child).prop "checked", newState
        else
          # Do nothing, outside of indexes
        true # continue looping

    else
      console.log "Could not find both indexes:", thisIndex, lastIndex if debug

  lastItemSelected = checkbox
  updateStates(parent)

# TODO requires i18n
itemSelectedText = (n) ->
  if n == 0
    "No items selected."
  else if n == 1
    "1 item selected."
  else
    "#{n} items selected."

itemSelectedSums = (sums) ->
  currencies = Object.keys(sums)
  return "" if currencies.length == 0

  currencies.sort()
  rows = currencies.map (currency) ->
    balance = sums[currency]
    basic = "#{accounting.toFixed(balance, 2)} #{currency}"
    full = "#{accounting.toFixed(balance, 8)} #{currency}"

    "<span data-tooltip=\"#{full}\">#{basic}</span>"

  if rows.length >= 4
    rows = rows.slice(0, 3)
    rows.push "..."

  "(#{rows.join(", ")})"

updateStates = (parent, children) ->
  children = $(parent).find(":checkbox:not(.select-all)")
  targetCheckbox = $(parent).find(".select-all")
  calculateSums = $(parent).find(".item-selected-sums").length > 0

  allChecked = true
  allUnchecked = true
  anyChecked = false
  selected = 0

  sums = {}

  children.each (index, child) ->
    isChecked = $(child).is(":checked")
    if isChecked
      allUnchecked = false
      anyChecked = true
      selected += 1
    else
      allChecked = false

    parentRow = $(child).parents("tr")
    if isChecked
      $(parentRow).addClass("selected")
    else
      $(parentRow).removeClass("selected")

    if calculateSums && isChecked
      if parentRow.attr("data-sum-value") && parentRow.attr("data-sum-currency")
        currency = parentRow.attr("data-sum-currency")
        sums[currency] ||= 0
        sums[currency] += (parentRow.attr("data-sum-value") * 1)

    true # continue looping

  console.log "determined to be", allChecked, allUnchecked if debug

  if allChecked
    $(targetCheckbox).prop "checked", true
    $(targetCheckbox).prop "indeterminate", false
  else if allUnchecked
    $(targetCheckbox).prop "checked", false
    $(targetCheckbox).prop "indeterminate", false
  else
    $(targetCheckbox).prop "indeterminate", true

  if anyChecked
    $(parent).find(".any-checked").show()
    $(parent).find(".enabled-when-any-checked").find("button, input, select").attr('disabled', false)
  else
    $(parent).find(".any-checked").hide()
    $(parent).find(".enabled-when-any-checked").find("button, input, select").attr('disabled', true)

  if selected > 1
    $(parent).find(".many-checked").show()
    $(parent).find(".enabled-when-many-checked").find("button, input, select").attr('disabled', false)
  else
    $(parent).find(".many-checked").hide()
    $(parent).find(".enabled-when-many-checked").find("button, input, select").attr('disabled', true)

  $(parent).find(".item-selected-count").html(itemSelectedText(selected))

  if calculateSums
    $(parent).find(".item-selected-sums").html(itemSelectedSums(sums))

  tooltips.apply(parent)

apply = (parent) ->
  turboEach document, 'form[data-checkboxes]', (element) ->
    console.log "shift-checkboxes on", element if debug

    $(element).find(".select-all").on 'click', (e) ->
      selectAll(element, e)

    $(element).find(":checkbox:not(.select-all)").on 'click', (e) ->
      selectItem(element, e)

    $(element).find("table td.checkbox").on 'click', (e) ->
      $(e.target).find("input[type=checkbox]").trigger 'click', e

    # Update with initial state
    updateStates(element)

module.exports = {
  apply: apply,
}
