$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach document, '.hamburger-opener', (element) ->
    $(element).on 'click', (e) ->
      $(".hamburger-target").addClass('visible')
      $(".hamburger-target").removeClass('hidden')
      e.preventDefault()
      return false

  turboEach document, "body", "body-hamburger-enabled", (element) ->
    $(element).on 'click', (e) ->
      if !$(e.target).is(".hamburger-target") && $(e.target).parents(".hamburger-target").length == 0
        $(".hamburger-target").removeClass('visible')
        $(".hamburger-target").addClass('hidden')

  turboEach document, '.hamburger-closer', (element) ->
    $(element).on 'click', (e) ->
      $(".hamburger-target").removeClass('visible')
      $(".hamburger-target").addClass('hidden')
      e.preventDefault()
      return false

module.exports = {
  apply: apply,
}
