$ = require('jquery')

turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach document, '[data-submit-on-change]', (element) ->
    console.log "submit-on-change on", element if debug

    $(element).on 'change', (e) ->
      console.log "Submitting!" if debug
      $(element).parents("form").submit()

module.exports = {
  apply: apply,
}
