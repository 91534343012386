$ = require('jquery')

turboEach = require('./turbo-each')
tooltips = require('./tooltips')

debug = false

show = (e) ->
  $(e).show()
  $(e).find("input").attr("disabled")

hide = (e) ->
  $(e).hide()
  $(e).find("input").removeAttr("disabled")

apply = (parent) ->
  turboEach document, '.toggle-button-group', (element) ->
    console.log "toggle button group on", element if debug

    checkbox = $(element).find(".toggle-button input[type=checkbox]")
    if checkbox.length == 0
      throw new Error("Could not find checkbox within toggle button group")

    $(checkbox).on 'change', (e) ->
      console.log "change event on", checkbox if debug

      target = $(element).find(".when-toggled")

      if $(checkbox).is(":checked")
        show(target)
      else
        hide(target)

      tooltips.apply(element)

    # init
    console.log "triggering checkbox change on", checkbox if debug
    $(checkbox).trigger 'change'

  # TODO this should be refactored with toggle-button-group (above),
  # the main differences is that the former assumes:
  # <.toggle-button-group> (per element)
  #   <input[type=checkbox].toggle-button> (just one)
  #   <.when-toggled> (just one)
  # whereas the latter assumes:
  # <.radio-button-group> (just one)
  #   <input[type=checkbox].toggle-button> (per element)
  #   <.when-toggled> (per element)
  turboEach document, '.radio-button-group', (element) ->
    console.log "radio button group on", element if debug

    radio = $(element).find(".radio-button input[type=radio]")
    if radio.length == 0
      throw new Error("Could not find radio within radio button group")

    $(radio).on 'change', (e) ->
      checkedRadio = $(element).find(".radio-button input[type=radio]:checked")
      console.log "change event on", radio if debug

      # May be empty if there are no checked elements
      id = $(checkedRadio).attr('data-show')

      $(element).find(".when-toggled").each (i, toggleElement) ->
        if $(toggleElement).attr('id') == id
          show(toggleElement)
        else
          hide(toggleElement)

      tooltips.apply(element)

    # init
    console.log "triggering radio change on", radio if debug
    $(radio).trigger 'change'

  # And a third function that does something similar
  turboEach document, '.switch-button-group', (element) ->
    console.log "switch button group on", element if debug

    target = $(element).find("input[type=radio], input[type=checkbox]")
    if target.length == 0
      throw new Error("Could not find element within switch button group")

    $(target).on 'change', (e) ->
      console.log "change event on", target if debug
      classesToToggle = $(target).attr("data-switches")
      console.log "toggling", classesToToggle if debug
      elementsToToggle = $(element).find(classesToToggle)

      if $(e.target).is(":checked")
        toShow = elementsToToggle.filter(".when-toggled-on")
        toHide = elementsToToggle.filter(".when-toggled-off")
      else
        toHide = elementsToToggle.filter(".when-toggled-on")
        toShow = elementsToToggle.filter(".when-toggled-off")

      toShow.each (i, e) ->
        show(e)
      toHide.each (i, e) ->
        hide(e)
      tooltips.apply(element)

    # init
    console.log "triggering switch change on", target if debug
    $(target).trigger 'change'

module.exports = {
  apply: apply,
}
