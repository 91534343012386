apply = () ->
  # For IE before Edge
  if !String.prototype.endsWith
    String.prototype.endsWith = (search, this_len) ->
      if this_len == undefined || this_len > @length
        this_len = this.length

      return @substring(this_len - search.length, this_len) == search

  if !String.prototype.startsWith
    String.prototype.startsWith = (search, pos) ->
      return @substr(!pos || pos < 0 ? 0 : +pos, search.length) == search

module.exports = apply
