$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach parent, 'a[data-inline-scroll][href*="#"]', (element) ->
    console.log "applying inline-anchor-scroll to", element if debug

    $(element).on 'click', (e) ->
      link = @href.split('#')

      # ignore orphaned # at end of URL
      if link[1] == ''
        return true

      current_root = window.location.href.split('#')[0]

      if link[0] == current_root
        anchor = $("##{link[1]}").offset().top

        # Optional: Update #hash of the current document.
        # However unless we also create a pushState() event handler
        # this will break the back button.
        #
        # if history.replaceState
        #   history.replaceState null, null, "##{link[1]}"

        $("html, body").animate({
          scrollTop: anchor
        }, 250) # in ms

        e.preventDefault()
        return false

      else
        window.location.href = @href

      return false

module.exports = {
  apply: apply,
}
