$ = require('jquery')
jqueryModal = require('jquery-modal')             # https://github.com/kylefox/jquery-modal
escapeHtml = require('escape-html')

turboEach = require('./turbo-each')
gaEvent = require('./ga-event')

debug = false

# '#foo' -> 'foo'
idFromSelector = (string) ->
  return string.substring(1)

addRelCloseToButtons = (element) ->
  # All buttons on the modal can be used to close the modal
  # (also allows the HTML5 to validate)
  element.find(".button.cancel:not(.disabled)").attr("rel", "modal:close")

showTargetModal = (modal) ->
  console.log "showing target", modal if debug

  if $(modal).data("analytics-action")
    category = $(modal).data("analytics-category")
    action   = $(modal).data("analytics-action")
    label    = $(modal).data("analytics-label")
    value    = $(modal).data("analytics-value")
    gaEvent(action, category, label, value)

  closeClass = if modal.find("header").length > 0
    "with-header"
  else
    "not-with-header"

  addRelCloseToButtons(modal)

  # for any select2s within the modal, trigger the onChange event, so they can be
  # populated as necessary
  modal.on 'modal:open', ->
    selects = modal.find("input, select")
    console.log "triggering change on ", selects if debug
    selects.trigger('change')

  modal.on 'modal:reopen', () ->
    modal.modal {
      fadeDuration:  100,
      closeClass:    closeClass,
      updateHistory: true,
      bodyScroll:    true,
      closeText:     '<span title="Close">&#xd7;</span>',
    }

  modal.trigger 'modal:reopen'

  return false

showRemoteModalFrom = (modalSelector, url, modal) ->
  needsUpdate = false

  if $(modalSelector).length == 0
    console.log "creating remote modal #{idFromSelector(modalSelector)} for #{url}" if debug

    analyticsContent = ""
    if $(modal).data("analytics-action")
      category = $(modal).data("analytics-category")
      action   = $(modal).data("analytics-action")
      label    = $(modal).data("analytics-label")
      value    = $(modal).data("analytics-value")
      analyticsContent = "
          data-analytics-category='#{escapeHtml(category)}'
          data-analytics-action='#{escapeHtml(action)}'
          data-analytics-label='#{escapeHtml(label)}'
          data-analytics-value='#{escapeHtml(value)}'"

    $(document.body).append("
      <div class='modal loading' style='display:none;' id='#{escapeHtml(idFromSelector(modalSelector))}' #{analyticsContent}>
        <div class='target'>
          <header>
            <h2>Loading...</h2>
          </header>
        <span class=\"loading\">Loading...</span></div>
      </div>")
    needsUpdate = true

  showTargetModal $(modalSelector)

  if needsUpdate
    console.log "fetching", url if debug

    $.ajax({
      method: 'GET',
      url: url,
    }).done((html) ->
      $(modalSelector).find("> div.target").html(html)
      $(modalSelector).removeClass("loading")

      # If there's a <form> in here, then when the modal closes, we want to
      # detach the form completely - or else, elements (particularly <label>s) that
      # refer to IDs across the same kinds of <form>s will reference the FIRST form
      $(modalSelector).on 'modal:after-close', (e) ->
        if $(modalSelector).find("form").length > 0
          console.log "removing remote modal containing form", modalSelector if debug
          $(modalSelector).remove()

      addRelCloseToButtons($(modalSelector))
      $(document).trigger 'openclerk:document-updated'
    ).catch((xhr, textStatus) ->
      failure = "#{xhr.status} #{xhr.responseText}"
      $(modalSelector).find("> div.target").html("<div class='error'>#{escapeHtml(failure)}</div>")

      $(modalSelector).on 'modal:close', () ->
        # If we get an error, then when we close the modal we should remove the entire modal,
        # so that clicking the modal again will send another request (which will hopefully work)
        $(modalSelector).remove()

      throw new Error("Could not load remote modal #{url}: #{failure}")
    )

  return false

refreshModals = () ->
  console.log "refreshing modals" if debug

  $("a[data-modal]:not(.disabled)").each (index, element) ->
    # important to stop turbolinks registering handlers twice
    unless $(element).data("modal-enabled")
      $(element).data("modal-enabled", true)
      console.log "registering modals on ", element if debug

      $(element).on 'click', (e) ->
        # hide any open dropdowns
        $(".jq-dropdown a[data-modal]:not(.disabled)").each (_, e2) ->
          $(e2).jqDropdown('hide') if $(e2).jqDropdown

        # do not capture clicks on inner <labels> etc
        actual_target = $(e.target).closest("a[data-modal]")
        target_modal = $(actual_target.data("modal"))

        # if it's a remote modal, and we haven't already loaded the content,
        # create a request to fetch the content
        # (NOTE only works with GET)
        if target_modal.length == 0 && url = $(actual_target).data("url")
          showRemoteModalFrom actual_target.data("modal"), url, actual_target
        else
          showTargetModal target_modal

        e.preventDefault()
        return false

apply = (parent) ->
  throw new Error("Not implemented yet")

module.exports = {
  showTargetModal: showTargetModal,
  addRelCloseToButtons: addRelCloseToButtons,
  showRemoteModalFrom: showRemoteModalFrom,
  refreshModals: refreshModals,
  apply: apply,
}
