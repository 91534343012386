# Track Turbolinks events with GA *or* gtag.js
# Based off https://github.com/joshdvir/google-analytics-turbolinks
if typeof Turbolinks != 'undefined' && Turbolinks.supported
  eventName = if typeof Turbolinks.controller != 'undefined' then 'turbolinks:load' else 'page:change'
  document.addEventListener eventName, (event) =>

    if window.gtag != undefined && window.GA_ID != undefined
      # gtag.js
      gtag('config', window.GA_ID, {
        'page_path': location.pathname,
        'page_title': document.title,
      })
    else if window.ga != undefined
      # Google Analytics
      ga('set', 'location', location.href.split('#')[0])
      ga('send', 'pageview', {"title": document.title})
    else if window._gaq != undefined
      _gaq.push(['_trackPageview'])
    else if window.pageTracker != undefined
      pageTracker._trackPageview();
