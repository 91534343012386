$ = require('jquery')
turboEach = require('./turbo-each')
escapeHtml = require('escape-html')
modals = require('./modals')

debug = false

uniqueIdCounter = 0

apply = (parent) ->
  turboEach document, '[data-lightbox] img', (element) ->
    console.log "lightbox on", element if debug

    id = "lightbox-element-#{uniqueIdCounter++}"

    $(document.body).append("
      <div class='modal lightbox' style='display:none;' id='#{escapeHtml(id)}'>
      </div>
    ")

    created = $(document.body).find("##{id}")
    # It would be nice to include the figcaption, but it doesn't style very nicely
    $(created).append($(element).clone())

    $(created).find("img").addClass("button cancel")

    $(element).on 'click', () ->
      console.log "showing lightbox modal for", element if debug
      modals.showTargetModal(created)

    $(created).find("img").on 'click', () ->
      $.modal.close()

module.exports = {
  apply: apply,
}
