debug = false

# TODO needs i18n
# Rounds down, too
humanize = (seconds) ->
  if seconds == undefined || seconds <= 0
    "now"
  else if seconds < 2
    "#{Math.floor(seconds)} sec"
  else if seconds < 1 * 60
    "#{Math.floor(seconds)} secs"
  else if seconds < 2 * 60
    "#{Math.floor(seconds / 60)} min"
  else if seconds < 1 * 60 * 60
    "#{Math.floor(seconds / 60)} mins"
  else if seconds < 2 * 60 * 60
    "#{Math.floor(seconds / (60 * 60))} hr"
  else
    "#{Math.floor(seconds / (60 * 60))} hrs"

humanizeMinutes = (seconds) ->
  if seconds == undefined || seconds < 2 * 60
    "1 min"
  else if seconds < 1 * 60 * 60
    "#{Math.floor(seconds / 60)} mins"
  else if seconds < 2 * 60 * 60
    "#{Math.floor(seconds / (60 * 60))} hr"
  else
    "#{Math.floor(seconds / (60 * 60))} hrs"

module.exports = {
  humanize: humanize,
  humanizeMinutes: humanizeMinutes,
}
