$ = require('jquery')

debug = false

deepExtend = (source, merge) ->
  $.extend true, {}, source, merge

candlestickFormat = {
  "legend": "none",
  "tooltip": {
    "isHtml": true,
  },
  "bar": { "groupWidth": "100%" },
  "colors": ["grey"],
  # Valid [ComboChart] values are 'line', 'area', 'bars', 'candlesticks' and 'steppedArea'.
  "seriesType": "candlesticks",
  "candlestick": {
    # red
    "fallingColor": { "strokeWidth": 2, "stroke": "#a52714", "fill": "#a52714" },
    # green
    "risingColor": { "strokeWidth": 2, "stroke": "#0f9d58", "fill": "#0f9d58" },
  }
}

normalFormat = (chartType, chartOptions) ->
  options = {
    "hAxis": {
      "format": "d-MMM",
    },
  }

  # Extra formatting options
  switch chartType
    when "candlestick-chart"
      options = deepExtend options, candlestickFormat

    when "stacked-chart"
      options = deepExtend options, { isStacked: true } # Equivalent to isStacked: 'absolute'

    when "stacked-100-chart"
      options = deepExtend options, { isStacked: 'percent' }

  if chartOptions
    chartOptions.forEach (option) ->
      console.log "Applying option #{option}" if debug

      switch option
        when "logarithmic"
          options = deepExtend options, {
            "vAxis": {
              "scaleType": "mirrorLog",  # mirrorLog to permit negative numbers
            }
          }
        when "zero-axis"
          options = deepExtend options, {
            "vAxis": {
              "minValue": 0,
            }
          }
        else
          throw new Error("Unknown chartOption #{option}")

  options

fullscreenFormat = (chartType, chartOptions) ->
  options = normalFormat(chartType, chartOptions)

  if chartType != "pie-chart"
    options = deepExtend options, {
      "legend": "none",
      "chartArea": {
        "height": "82%",
        "width": "83%",
        "top": "6%",
        "left": "13%",
      }
    }
  else
    options = deepExtend options, {
      "chartArea": {
        "height": "84%",
        "width": "89%",
        "top": "6%",
        "left": "8%",
      }
    }

  options

portfolioFormat = (chartType, chartOptions) ->
  options = normalFormat(chartType, chartOptions)

  if chartType != "pie-chart"
    options = deepExtend options, {
      "legend": "none",
      "chartArea": {
        "height": "82%",
        "width": "82%",
        "top": "6%",
        "left": "13%",
      }
    }
  else
    options = deepExtend options, {
      "chartArea": {
        "height": "85%",
        "width": "90%",
        "top": "7.5%",
        "left": "10%",
      }
    }

  options

inlineFormat = (chartType, chartOptions) ->
  deepExtend fullscreenFormat(chartType, chartOptions), {
    "chartArea": {
      "width": "98%",
      "height": "98%",
      "top": "1%",
      "left": "1%",
    },
    "hAxis": {
      "textPosition": "none",
      "gridlines": {
        "color": "none",
      },
    },
    "vAxis": {
      "textPosition": "none",
      "gridlines": {
        "color": "none",
      },
    },
    "backgroundColor": {
      "fill": "transparent",
    },
  }

options = (chartType, format, chartOptions) ->
  result = switch format
    when "fullscreen"
      fullscreenFormat(chartType, chartOptions)

    when "portfolio", "account"
      portfolioFormat(chartType, chartOptions)

    when "inline", "loading-status"
      inlineFormat(chartType, chartOptions)

    else
      normalFormat(chartType, chartOptions)

  console.log "Resulting format", result if debug
  return result

# Google chart colours, in order
blue = "#3366cc"
red = "#dc3912"
yellow = "#ff9900"
green = "#109618"
magenta = "#990099"
violet = "#3b3eac"
cyan = "#0099c6"
pink = "#dd4477"
lime = "#66aa00"
redOrange = "#b82e2e"
color1 = "#316395"
color2 = "#994499"
color3 = "#22aa99"
verysick = "#aaaa11"
purple = "#6633cc"
orange = "#e67300"
darkRed = "#8b0707"
aqua = "#329262"
color4 = "#5574a6"
color5 = "#3b3eac"

technicalIndicators = (data, indicators) ->
  return [] if data.length == 0

  dataLength = data[0].length

  result = {}

  indicators.forEach (type, index) ->
    column = index + 1 # dataLength - index - 1
    # We provide the concept ('sma'), not the representation ('line'), so that
    # we can have consistent colours and styles between the same indicators across
    # all charts
    result[column] = switch type
      when "sma", "bollinger-sma"
        { "type": "line", "lineWidth": 1, "color": yellow }
      when "ema"
        { "type": "line", "lineWidth": 1, "color": orange }
      when "rsi"
        { "type": "line", "lineWidth": 1, "targetAxisIndex": 1, "color": cyan }
      when "bollinger-upper"
        { "type": "line", "lineWidth": 1, "color": green }
      when "bollinger-lower"
        { "type": "line", "lineWidth": 1, "color": red }
      else
        throw new Error("Unknown technical indicator type '#{type}'")

  return { "series": result }

module.exports = {
  technicalIndicators: technicalIndicators,
  options:             options,
}
