$ = require('jquery')

debug = false

window.ActiveDynamicAjax = []

# TODO we might want to refactor this garbage collection style into
# another component
garbageCollectDynamicAjax = () ->
  ajax_to_check = window.ActiveDynamicAjax
  window.ActiveDynamicAjax = []

  ajax_to_check.forEach (ajax) ->
    if $(ajax.selector) && $(ajax.selector).is(":visible")
      # ajax is still on the page, continue
      # (it may be on the page but hidden with Turbolinks)
      window.ActiveDynamicAjax.push ajax
      console.log "[ajax] keeping", ajax if debug
    else
      # ajax has disappeared, remove the interval and let GC take over
      window.clearInterval ajax.interval_id
      console.log "[ajax] GC", ajax if debug

updateAjax = (element, json, key) ->
  wantedClass = ".#{json[key]}"

  console.log "[ajax] showing", wantedClass if debug

  $(element).find(".dynamic-template").each (index, e) ->
    if $(e).is(wantedClass)
      $(e).css 'display', 'block'
    else
      $(e).css 'display', 'none'

apply = () ->
  garbageCollectDynamicAjax()

  $("[data-dynamic-ajax]").each (index, element) ->
    unless $(element).data("dynamic-ajax-enabled")
      $(element).data("dynamic-ajax-enabled", true)

      url = $(element).attr("data-dynamic-ajax")
      interval = $(element).attr("data-dynamic-interval") || 10
      key = $(element).attr("data-dynamic-key") || "template"

      ajaxIntervalFunction = () ->
        console.log "[ajax] fetching", url if debug

        requestFailed = (xhr, textStatus) ->
          if xhr.status == 0
            # ignore
          else
            throw new Error("Could not request dynamic AJAX #{url}: #{xhr.status} #{xhr.statusText}")

        $.ajax({
          method:   'GET',
          url:      url,
          dataType: 'json',
        }).done((json) ->
          updateAjax(element, json, key)
        ).fail(requestFailed)

      interval_id = window.setInterval ajaxIntervalFunction, interval * 1000 # seconds

      window.ActiveDynamicAjax.push {
        selector:    element,
        update:      ajaxIntervalFunction,
        interval_id: interval_id,
      }

      # and run it now
      ajaxIntervalFunction()

      # register it with any force refresh links
      $(element).find(".force-refresh").each (_, e) ->
        unless $(e).data("dynamic-ajax-force-refresh-enabled")
          $(e).data("dynamic-ajax-force-refresh-enabled", true)

          $(e).on 'click', ajaxIntervalFunction

module.exports = {
  apply: apply,
}
