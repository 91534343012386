formatDate = require("date-fns/format")
smartTruncate = require("smart-truncate")
isNumeric = require("isnumeric")
escapeHtml = require('escape-html')
accounting = require('accounting')

formatPercent = (number) ->
  accounting.formatMoney(number * 100, '')

truncate = (str) ->
  smartTruncate(str, 20)

format = (item, type = undefined) ->
  if item == undefined || item == null
    return "<span class=\"undefined\"></span>"

  if item.hasOwnProperty("link")
    if item['link'] == null
      "#{format(item['label'], type)}"
    else
      "<a href=\"#{escapeHtml(item['link'])}\">#{format(item['label'], type)}</a>"
  else if item.getMonth
    "<span title='#{escapeHtml(item)}'>#{formatDate(item, "D MMM YYYY")}</span>"
  else
    switch type
      when "percent-in-week"
        title = "#{accounting.formatMoney(item * 100, '', 4)}%"

        if item > 0
          "<span class=\"percent increase\" data-tooltip=\"Up #{escapeHtml(title)} in one week\">#{formatPercent(item)}%</span>"
        else if item < 0
          "<span class=\"percent decrease\" data-tooltip=\"Down #{escapeHtml(title)} in one week\">#{formatPercent(item)}%</span>"
        else
          "<span class=\"percent no-change\" data-tooltip=\"No change in one week\">-</span>"

      when "percent"
        title = "#{accounting.formatMoney(item * 100, '', 4)}%"

        if item > 0
          "<span class=\"percent increase\" title=\"#{escapeHtml(title)}\">#{formatPercent(item)}%</span>"
        else if item < 0
          "<span class=\"percent decrease\" title=\"#{escapeHtml(title)}\">#{formatPercent(item)}%</span>"
        else
          "<span class=\"percent no-change\" title=\"#{escapeHtml(title)}\">-</span>"

      when "balance"
        formatted = if Math.abs(item) == 0
            "-"
          else if Math.abs(item) < 1e-3
            "#{accounting.formatMoney(item * 1e3, '')}e-3"
          else
            accounting.formatMoney(item, '')

        "<span class=\"balance\" title=\"#{escapeHtml(item)}\">#{escapeHtml(formatted)}</span>"
      else
        "<span title=\"#{escapeHtml(item)}\">#{escapeHtml(truncate(item))}</span>"

formatColumn = (column, type = undefined) ->
  classNames = if column == undefined || column == null
    "undefined"
  else if isNumeric(column)
    "number"
  else if column.getMonth
    "date"
  else
    "string"

  classNames += " " + switch type
    when "currency"
      "currency"
    when "percent"
      "percent"
    when "balance"
      "balance"
    else
      ""

  "<td class='#{classNames}'>#{format(column, type)}</td>"

renderHeadings = (headings, types = []) ->
  rows = headings.map (row) ->
    "<th>#{escapeHtml(row)}</th>"

  "<thead><tr>#{rows.join("")}</tr></thead>"

renderRows = (data, types = []) ->
  rows = data.map (row) ->
    columns = row.map (e, index) ->
      formatColumn(e, types[index])

    "<tr>#{columns.join("")}</tr>"

  "<tbody>#{rows.join("")}</tbody>"

renderTableAsHtml = (data, types = [], firstRowIsData = false, classNames = "") ->
  if !firstRowIsData
    headings = data.shift()
    header = renderHeadings(headings)
  else
    header = ""

  "<table class='table-chart #{escapeHtml(classNames)}'>#{header}#{renderRows(data)}</table>"

renderTransposedRow = (data, types = []) ->
  if data.length == 0
    return "<tr><td class=\"empty\">(empty)</td></tr>"

  rows = data.map (row) ->
    if row.length == 0
      ""
    else
      first = row.shift()
      columns = row.map (e, index) ->
        formatColumn(e, types[index + 1])

      "<tr><th>#{format(first)}</th>#{columns.join("")}</tr>"

  rows.join("")

# Data format:
# [
#   [Date, 12345],
#   [USD, 1.23],
#   [BTC, 3.45],
# ]
renderTransposedTableAsHtml = (data, types = [], firstRowIsData = false, classNames = "", headings = undefined) ->
  header = ""
  headerLength = 0

  if headings
    headerItems = headings.map (row) ->
      "<th>#{escapeHtml(row)}</th>"

    header = "<thead><tr>#{headerItems.join("")}</tr></thead>"
    headerLength = headings.length

  if !firstRowIsData # i.e. first column is a heading
    rows = data.map (row) ->
      columns = row.map (e, index) ->
        if index == 0
          "<th>#{format(e, types[index])}</th>"
        else
          formatColumn(e, types[index])

      "<tr>#{columns.join("")}</tr>"
  else
    rows = data.map (row) ->
      columns = row.map (e, index) ->
        formatColumn(e, types[index])

      "<tr>#{columns.join("")}</tr>"

  if data.length == 0
    classNames += " empty"

    if headerLength > 0
      # TODO i18n
      # this is also duplicated with charts.coffee#renderEmpty()
      header += "<tr><td colspan=\"#{headerLength}\"><div class='no-data'><div class='message'>Couldn't find anything to display. Try changing the period or chart options.</div></div></td></tr>"

  "<table class='table-chart #{escapeHtml(classNames)}'>#{header}#{rows.join("")}</table>"

module.exports = {
  render:           renderTableAsHtml,
  renderTransposed: renderTransposedTableAsHtml,
}
