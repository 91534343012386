$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

# If an input tied to a checkbox is changed, then automatically check the checkbox
apply = (parent) ->
  turboEach parent, "[data-checkbox-parent]", (element) ->
    console.log "appling checkbox parent to", element if debug

    $(element).find("input, select, .select2").on 'change keypress change.select2', (e) ->
      console.log "change on", e.target if debug
      $(element).find("input[type=checkbox]").each (_, e2) ->
        console.log "looking at", e2, " != ", e.target if debug
        if e.target != e2
          $(e2).prop('checked', 'checked')
      return true

module.exports = {
  apply: apply,
}
