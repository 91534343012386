$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach parent, ".filterable", (element) ->
    if $(element).find("* .filterable").length != 0
      # If you do this, things will fall apart. We could satisfy this using some
      # mixture of parents(), :not and nextUntil(), but it would be easier
      # (and probably better architecture) to not include them within each other.
      console.error "Parent: ", element
      console.error "Child: ", $(element).find("* .filterable")
      throw new Error("Cannot contain a filterable within another filterable: #{element}")

    console.log "filterable on", element if debug

    # trigger on the filter source changing
    filter_source = $(element).find("[data-filter-source]")

    # which elements are we going to filter against?
    filter_selector = "[data-filter]"
    console.log "filtering with", filter_selector if debug

    # for debugging, are you sure you have an element with [data-filter] within the
    # .filterable?
    if $(element).find(filter_selector).length == 0
      console.warn "There are no matching", filter_selector, "filterable elements within", element

    elementMatchesAllSelectors = (filterElement, selectors) ->
      reduceFn = (result, value) ->
        filter = $(value).data("filter-show")
        if $(value).is("input[type=text]")
          if $(value).val().trim().length == 0
            # Ignore empty search strings
            filter = "*"
          else
            filter = filter.replace("?", $(value).val())

        console.log "filter -> ", filter if debug
        result and $(filterElement).is(filter)

      selectors.toArray().reduce reduceFn, true

    triggerFilterChange = (e) ->
      console.log "triggering filter change on", e if debug

      selectors = $(element).find("select[data-filter-source] > option:selected, input[type=checkbox][data-filter-source]:checked, input[type=text][data-filter-source]")
      console.log "selectors are", selectors if debug

      # hide all filter-targets, except those that match the filter
      anyMatch = false
      $(element).find(filter_selector).each (_, filterElement) ->
        shown = elementMatchesAllSelectors(filterElement, selectors)
        console.log filterElement, " -> ", shown if debug

        if shown
          anyMatch = true
          $(filterElement).find("select").each (_, select) ->
            $(select).attr("disabled", false)
          $(filterElement).show()
          console.log "showing", filterElement if debug

        else
          $(filterElement).find("select").each (_, select) ->
            $(select).attr("disabled", "disabled")
          $(filterElement).hide()
          console.log "hiding", filterElement if debug

      if anyMatch
        $(element).find("[data-filter-none]").hide()
        console.log "hiding no-match", $(element).find("[data-filter-none]") if debug
      else
        $(element).find("[data-filter-none]").show()
        console.log "showing no-match", $(element).find("[data-filter-none]") if debug

    filter_source.on 'change keyup openclerk:refresh-filters', triggerFilterChange
    triggerFilterChange()

module.exports = {
  apply: apply,
}
