$ = require('jquery')
turboEach = require('./turbo-each')
qtip2 = require('qtip2')
escapeHtml = require('escape-html')

debug = false

# <a disabled> doesn't do anything in HTML5, so we need to prevent clicks manually
apply = (parent) ->
  turboEach parent, "[data-tooltip]", (element) ->
    console.log "appling tooltip to", element if debug

    $(element).qtip {
      content:
        text: escapeHtml($(element).data('tooltip'))
        title: escapeHtml($(element).data('tooltip-header')) if $(element).data('tooltip-header')
      style:
        classes: 'qtip-bootstrap qtip-rounded'
      position:
        at: $(element).attr("data-tooltip-position") || 'bottom center'
        viewport: $("body") # for mobile, dont render outside the viewport
      show:
        solo: true
    }

  turboEach parent, "[data-permanent-tooltip]", (element) ->
    console.log "appling permanent tooltip to", element if debug

    $(element).qtip {
      content:
        text: escapeHtml($(element).data('permanent-tooltip'))
        title: escapeHtml($(element).data('permanent-tooltip-header')) if $(element).data('permanent-tooltip-header')
      style:
        classes: 'qtip-bootstrap qtip-rounded'
      position:
        at: 'bottom middle'
        viewport: $("body") # for mobile, dont render outside the viewport
      hide:
        event: 'unfocus'
      show:
        event: 'click mouseenter'
        solo: true
    }

module.exports = {
  apply: apply,
}
