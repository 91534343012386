# A simple, abstract queue to let clients queue up lots of
# different requests, each of which can be asynchronous,
# and we guarantee that only N of them will ever run at once.
# Probably would make sense to use Promises for this, and not
# hand-write our own.

$ = require('jquery')

debug = false

queue = []
limit = 3
executing = []

setLimit = (n) ->
  limit = n
  poll()

poll = () ->
  console.log "Polling..." if debug

  if executing.length < limit && queue.length > 0
    console.log "Currently executing #{executing.length} of #{limit}: running another function..." if debug

    fn = queue.shift()
    executing.push(fn)

    # Execute the function, and give it a callback to run the next one
    fn () ->
      console.log "Function complete" if debug
      index = executing.indexOf(fn)
      if index >= -1
        executing.splice(index)
        poll()

# Function must accept an argument, doneFunction, to
# be called when the (asynchronous) function has completed
enqueue = (fn) ->
  console.log "Enqueueing ", fn if debug
  queue.push(fn)
  poll()

reset = () ->
  queue = []
  executing = []
  poll()

module.exports = {
  setLimit:  setLimit,
  limit:     limit,
  executing: executing,
  queue:     queue,
  enqueue:   enqueue,
  reset:     reset,
  poll:      poll,
}
