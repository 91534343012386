$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

# <a disabled> doesn't do anything in HTML5, so we need to prevent clicks manually
apply = (parent) ->
  turboEach parent, "a[disabled]", (element) ->
    console.log "will ignore clicks on", element if debug

    $(element).on 'click', (e) ->
      console.log "ignoring disabled <a>", element if debug

      e.preventDefault()
      return false

module.exports = {
  apply: apply,
}
