$ = require('jquery')
escapeHtml = require('escape-html')
unique = require('array-unique').immutable
OnScreen = require('onscreen')

tableAsHtml = require('./table_as_html')
loadingBarAsHtml = require('./loading-bar-as-html')
chartFormats = require('./chart_formats')
polyfillString = require('./core/polyfill/string')
humanizeMinutes = require('./core/time').humanizeMinutes

tooltips = require('./core/tooltips')
gaEvent = require('./core/ga-event')
turboEach = require('./core/turbo-each')
sortable = require('./core/sortable')
window.ExecutionQueue = jsQueue = require('./core/js-queue')

debug = false
debugRefresh = false
renderProgressBar = false
enableResize = false
enableCharts = true
resizeDelay = 100 # ms, too low = very CPU intensive, too high = may cause display issues

# All charts on the page, including automatic and non-automatic (e.g. preview) charts
window.ActiveCharts = []

chartArguments = [
  "chart-type",
  "portfolio-id",
  "public-key",
  "exchange-id",
  "exchange-pair-id",
  "account-id",
  "address-id",
  "offset-id",
  "generic-api-id",
  "currency-id",
  "days",
  "is-stacked",
  "is-100-stacked",
  "is-logarithmic",
  "is-zero-axis",

  # technical indicators
  "sma-period",
  "ema-period",
  "rsi-period",
  "bollinger-bands-period",
]

garbageCollectCharts = () ->
  charts_to_check = window.ActiveCharts
  window.ActiveCharts = []

  charts_to_check.forEach (chart) ->
    if $(chart.selector) && $(chart.selector).is(":visible")
      # chart is still on the page, continue
      # (it may be on the page but hidden with Turbolinks)
      window.ActiveCharts.push chart
      console.log "keeping", chart if debug
    else
      # chart has disappeared, remove the timeout and let GC take over
      window.clearTimeout chart.timeout_id if chart.timeout_id
      console.log "GC", chart if debug

stringifyFirstElementsAsDates = (collection) ->
  collection.map (row) ->
    return row unless row.length > 0
    # using a Date() directly here, sadly, messes up the widths
    # of the Google Charts candlestick chart.
    # row[0] = new Date(row[0])
    row[0] = formatDate(new Date(row[0]))
    row

updateFirstElementsAsDates = (collection, firstRowIsData, tableData = false) ->
  collection.map (row, index) ->
    return row unless row.length > 0
    return row if index == 0 && !firstRowIsData

    # if the first column is "Date", and we're processing a table,
    # then it stands to reason that the remaining columns are actually dates
    if tableData && row[0] == "Date"
      return row.map (column, index) ->
        if index == 0
          column
        else if column > 0
          new Date(column * 1000)
        else
          column

    return row unless row[0] > 0 # numeric only

    # Need to clone the row before we start modifying it
    cloned_row = row.slice(0)
    cloned_row[0] = new Date(cloned_row[0] * 1000)
    cloned_row

formatDate = (date) ->
  "#{date.getDate()}-#{monthName(date.getMonth())}"

monthName = (monthNumber) ->
  # TODO: i18n needs to be applied here
  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][monthNumber]

isEmpty = (array) ->
  array && array.length == 0

cacheParameter = () ->
  Math.floor(Date.now() / (1000 * 60)) # allow for caching to a minute

requestChartUpdate = (element, forceUpdate = false) ->
  window.ActiveCharts.forEach (chart) ->
    if chart.selector == element
      chart.update(forceUpdate)

# Populate the chart field, but display it as "loading..."
# until the AJAX request has returned
displayLoading = (element) ->
  if !$(element).is(":visible")
    return garbageCollectCharts()

  template = selectChartTemplate(element)
  $(element).html(template.html())

  $(element).find(".title").hide()
  $(element).find(".summary").hide()
  $(element).find(".last-updated").hide()

  $(element).find(".notifications").hide()

  $(element).find(".chart-target").html('<div class="loading"><span class="loading">Loading...</span></div>')

createSummariesHtml = (collection) ->
  # Do not display more than 5 currencies at once in the summary HTML
  if collection.length > 5
    result = collection.slice(0, 5).map (row) ->
      "<li>#{row}</li>"
    result.push "<li>...</li>"
    result
  else
    collection.slice(0, 5).map (row) ->
      "<li>#{row}</li>"

retriggerSortable = (element) ->
  resort = false
  console.log "resorting on", $(element).parents(".sortable") if debug
  $(element).parents(".sortable").trigger "update", resort

uniqueIdCounter = 0

uniqueId = (element) ->
  unless $(element).data("unique-id")
    $(element).data("unique-id", "unique-element-#{uniqueIdCounter++}")

  $(element).data("unique-id")

appendSummaryStyles = (element, value) ->
  console.log "appending", value, "styles to", element if debug
  $(element).removeClass("percent positive negative")

  if value.endsWith('%')
    $(element).addClass("percent")

  if value.startsWith('-')
    $(element).addClass("negative")
  else
    $(element).addClass("positive")

# Take a `dependencies` object and turn it into i18n text.
progressText = (deps) ->
  active = deps['active']

  # e.g. the chart is being rendered publicly
  return "Recalculating..." unless active

  objectLink = () ->
    "<a href=\"#{escapeHtml(active["link"])}\" title=\"#{escapeHtml(active["description"])}\">#{escapeHtml(active["description"].substring(0, 20))}</a>"

  # this should be easier to understand for huge accounts
  progressNumber = "(#{escapeHtml(deps["completed"])} of #{escapeHtml(deps["total"])})"

  # TODO will need i18n
  activityText = switch active["activity"]
    when "running"
      "Recalculating #{objectLink()}..."
    when "fetching"
      "Fetching #{objectLink()}..."
    when "queued"
      "Updating #{objectLink()}..."
    when "completed"
      "Completed #{objectLink()}"
    else
      active["activity"]

  "#{activityText} #{progressNumber}"

progressTooltip = (deps) ->
  if deps["active"]
    label = switch deps["active"]["object"]
      when "Portfolio"
        "your portfolio"
      else
        "your accounts"
  else
    label = "this chart"

  expected = "Estimated time remaining: #{humanizeMinutes(deps['latency'] * 2)}."

  "We're updating #{label} in the background on our servers. #{expected}"

linkOptionsMenu = (element) ->
  $(element).find("[data-dropdown-source]").each (index, e) ->
    # We want to detach the dropdown, but also not cause any
    # existing dropdowns currently open to close (this is
    # jerky and janky).
    #
    # TODO until we can build jquery-dropdown, and add e.g.
    # `{ hideExisting: false }` as data to jqDropdown,
    # we need to detach the dropdown manually
    # $(e).jqDropdown('detach')
    $(e).removeAttr('data-jq-dropdown')

    $(element).find("[data-dropdown-target]").each (index2, e2) ->
      $(e2).attr('id', "dropdown-#{uniqueId(element)}")
      $(e).jqDropdown('attach', "##{$(e2).attr('id')}")

dataIsEmpty = (transformedData, originalData) ->
  (originalData["first-row-is-data"] && transformedData.length == 0) ||
    (!originalData["first-row-is-data"] && transformedData.length == 1)

renderEmpty = (element) ->
  # TODO needs i18n
  $(element).html("<div class='no-data'><div class='message'>Couldn't find anything to display. Try changing the period or chart options.</div></div>")

renderMissingGoogle = (element) ->
  # TODO needs i18n
  $(element).html("<div class='no-data'><div class='message'>The Google Charts library could not be loaded. Try refreshing your browser or clearing your cache.</div></div>")

selectChartTemplate = (element) ->
  selector = switch $(element).data("format")
    when "inline"
      "#inline-chart-template"
    when "loading-status"
      "#loading-chart-template"
    else
      "#chart-template"

  template = $(selector)
  if template.length == 0
    console.warn "Could not find chart template ", selector, "to render on page"
    return

  template

updateChart = (element, data, resizing = false) ->
  if typeof element == 'undefined'
    throw new Error("Cannot display an undefined chart")

  # If we're only resizing the chart, and we don't actually need to change the DOM at all,
  # then we should bail out early to vastly improve performance
  if resizing
    if data["chart-type"] == "table" || data["chart-type"] == "transposed-table"
      # no need to do any resizing; let CSS resize it for us
      console.log "skipping table resize" if debug
      return

    if $(element).data('current-width') == $(element).css('width')
      # element is the same size as it was before; no need to resize
      # (this also means resizing > mobile becomes super smooth)
      console.log "skipping resize" if debug
      return

  # If there was already a chart on this element, clear it
  if $(element).data('existing-chart')
    $(element).data('existing-chart').clearChart()
    $(element).removeData('existing-chart')

  options = chartFormats.options(data["chart-type"], $(element).data("format"), data["options"])

  if data["technical-indicators"]
    options = $.extend {}, options, chartFormats.technicalIndicators(data["data"], data["technical-indicators"])

  console.log "options:", options if debug

  template = selectChartTemplate(element)

  needsNewHtml = if data["chart-type"] == "loading"
    typeof element.updateProgress != 'function'
  else
    true

  # Initialise the template
  if needsNewHtml
    $(element).html(template.html())

  classes = $(element).attr('class') + ' ' + template.attr('class')
  classes = unique(classes.split(" "))
  $(element).attr('class', classes.join(" "))

  # Update the various properties
  $(element).find(".title").text(data["title"])
  $(element).find(".title").attr("href", data["title-link"])

  $(element).find(".summary").text(data["summary"])
  $(element).find(".summary").attr("title", data["summary-title"])
  appendSummaryStyles($(element).find(".summary"), data["summary"])

  $(element).find(".summaries").html(createSummariesHtml(data["summaries"]))
  $(element).find(".summaries").attr("title", data["summary-title"])
  $(element).find(".last-updated").text(data["last-updated"])

  unless $(element).find(".refresh").data("has-refresh-click")
    $(element).find(".refresh").data("has-refresh-click", true)
    $(element).find(".refresh").on 'click', (e) ->
      gaEvent('manually refresh chart', 'charts', data["chart-type"])
      requestChartUpdate(element, true)

  $(element).find(".notifications").attr("data-notifications", "#{data["notifications"]}")
  if data["notifications"]
    $(element).find(".notifications").attr("data-tooltip", "Notifications enabled")
  else
    $(element).find(".notifications").attr("data-tooltip", "Notifications available")

  if data["notifications-url"]
    $(element).addClass('with-notifications')
    $(element).find(".notifications").attr("href", data["notifications-url"])
  else
    $(element).removeClass('with-notifications')
    $(element).find(".notifications").removeAttr("href")

  if data["progress"]
    deps = data["progress"]["dependencies"]

    if data["progress"]["ready"] || deps["total"] == 0
      $(element).find(".progress").hide()
      $(element).addClass('chart-ready')
    else
      $(element).find(".progress").show()
      $(element).removeClass('chart-ready')

      if renderProgressBar
        current_task = (if deps["active"] then deps["active"]["progress"] else null) || 0
        bar_percent = (deps["completed"] + current_task) / deps["total"]
        $(element).find(".progress .progress-bar .bar").css(width: "#{bar_percent * 100}%")

      $(element).find(".progress .status .loading").html(progressText(deps))
      $(element).find(".progress .status .loading").attr("data-tooltip", progressTooltip(deps))

  # link up options menu
  linkOptionsMenu(element)

  ["force-update", "notifications", "csv", "clone"].forEach (linkTitle) ->
    $(element).find("[data-#{linkTitle}-link]").each (index, e) ->
      if data["#{linkTitle}-url"]
        $(e).show()
        $(e).attr('href', data["#{linkTitle}-url"])
      else
        $(e).hide()

  $(element).find("[data-next-update-title]").each (index, e) ->
    if data["next-update"]
      $(e).attr('title', "Next update in #{data["next-update"]}")
    else
      $(e).attr('title', '')

  hasModifyLinks = false
  $(element).find("[data-edit-modal]").each (index, e) ->
    # TODO it's probably a good idea to not rely on modal-id
    # here; we could generate a modal-id automatically perhaps
    if data['edit-url'] && data['modal-id']
      $(e).show()
      $(e).attr('href', data['edit-url'])
      $(e).attr('data-modal', "##{data['modal-id']}")
      $(e).attr('data-url', data['edit-url'])
      hasModifyLinks = true
      $(document).trigger 'openclerk:refresh-modals'
    else
      $(e).hide()

  $(element).find("[data-delete-link]").each (index, e) ->
    if data["delete-url"]
      $(e).show()
      $(e).attr('href', data['delete-url'])
      hasModifyLinks = true
    else
      $(e).hide()

  # Show the bottom divider if there are extra options that need a divider shown
  # (Ideally this would be a feature of jq-dropdown)
  $(element).find(".jq-dropdown-divider[data-modify-divider]").each (index, e) ->
    if hasModifyLinks
      $(e).show()
    else
      $(e).hide()

  $(element).show()
  retriggerSortable(element)

  chartTarget = element.getElementsByClassName("chart-target")[0]
  $(chartTarget).attr('data-chart-type', data['chart-type'])

  switch data["chart-type"]
    when "candlestick-chart"
      transformed = updateFirstElementsAsDates(data["data"], data["first-row-is-data"])
      console.log "data:", transformed if debug

      if dataIsEmpty(transformed, data)
        renderEmpty(chartTarget)
      else if google.visualization == undefined || typeof google.visualization.arrayToDataTable != 'function'
        renderMissingGoogle(chartTarget)
      else
        data = google.visualization.arrayToDataTable(transformed, data["first-row-is-data"])
        data.setColumnProperties(5, {'role': 'tooltip', 'html': true})
        chart = new google.visualization.ComboChart(chartTarget)
        chart.draw(data, options)
        $(element).data('existing-chart', chart)

    when "line-chart"
      transformed = updateFirstElementsAsDates(data["data"], data["first-row-is-data"])
      console.log "data:", transformed if debug

      if dataIsEmpty(transformed, data)
        renderEmpty(chartTarget)
      else if google.visualization == undefined || typeof google.visualization.arrayToDataTable != 'function'
        renderMissingGoogle(chartTarget)
      else
        data = google.visualization.arrayToDataTable(transformed, data["first-row-is-data"])
        chart = new google.visualization.LineChart(chartTarget)
        chart.draw(data, options)
        $(element).data('existing-chart', chart)

    when "stacked-chart", "stacked-100-chart"
      transformed = updateFirstElementsAsDates(data["data"], data["first-row-is-data"])
      console.log "data:", transformed if debug

      if dataIsEmpty(transformed, data)
        renderEmpty(chartTarget)
      else if google.visualization == undefined || typeof google.visualization.arrayToDataTable != 'function'
        renderMissingGoogle(chartTarget)
      else
        data = google.visualization.arrayToDataTable(transformed, data["first-row-is-data"])
        chart = new google.visualization.AreaChart(chartTarget)
        chart.draw(data, options)
        $(element).data('existing-chart', chart)

    when "pie-chart"
      transformed = data["data"]
      console.log "data:", transformed if debug

      if dataIsEmpty(transformed, data)
        renderEmpty(chartTarget)
      else if google.visualization == undefined || typeof google.visualization.arrayToDataTable != 'function'
        renderMissingGoogle(chartTarget)
      else
        data = google.visualization.arrayToDataTable(transformed, data["first-row-is-data"])
        chart = new google.visualization.PieChart(chartTarget)
        chart.draw(data, options)
        $(element).data('existing-chart', chart)

    when "table"
      transformed = updateFirstElementsAsDates(data["data"], data["first-row-is-data"], true)
      html = tableAsHtml.render(transformed, data["types"], data["first-row-is-data"], "sortable")

      $(chartTarget).html(html)

    when "transposed-table"
      transformed = updateFirstElementsAsDates(data["data"], data["first-row-is-data"], true)
      html = tableAsHtml.renderTransposed(transformed, data["types"], data["first-row-is-data"], "sortable", data["headings"])

      $(chartTarget).html(html)

    when "loading"
      if !needsNewHtml
        element.updateProgress(data["data"])
      else
        html = loadingBarAsHtml.render(data["data"])
        $(chartTarget).html(html)
        element.updateProgress = (data) ->
          loadingBarAsHtml.update(element, data)

      # hide any .hide-when-loaded if the loading is complete
      if data["data"]["percent"] * 1 >= 100
        $(element).parents(".hide-when-loaded").fadeOut()

    when "empty"
      # Do nothing

    else
      updateError(element, "Unknown chart-type #{data["chart-type"]}")
      throw new Error("Unknown chart-type #{data["chart-type"]}")

  $(element).data('current-width', $(element).css('width'))

  tooltips.apply(element)
  sortable.apply(element)

updateError = (element, message, info = []) ->
  if $(element).hasClass("successful")
    # Don't replace a 'working' chart with an error message
    console.error message
    return

  displayMessage = if isEmpty(info)
    message
  else
    info.join("; ")

  $(element).html("<header></header><div class=\"chart-target\"><div class=\"error refresh\">#{escapeHtml(displayMessage)}</div></div>")

  unless $(element).find(".error").data("has-error-click")
    $(element).find(".error").data("has-error-click", true)
    $(element).find(".error").click (e) ->
      gaEvent('refresh error', 'charts', displayMessage)
      requestChartUpdate(element, true)

updateChartOrError = (chart, resizing = false) ->
  element = chart.selector
  json = chart.last_json

  if json["success"]
    updateChart(element, json["result"], resizing)
    chart.hadSuccess = true
    $(element).addClass("successful")
  else
    updateError(element, json["message"])

registerChart = (url, element, data, automaticRefresh = true) ->
  if typeof element == 'undefined'
    throw new Error("Cannot register an undefined chart")

  chartIntervalFunction = (forceUpdate = false) ->
    if !$(element).is(":visible")
      return garbageCollectCharts()

    jsQueue.enqueue (doneFunction) ->
      this_url = url
      this_url += "&type=#{data.chart_type}"
      this_url += "&forceUpdate=#{Date.now()}" if forceUpdate

      console.log "fetching", this_url if debug

      # It appears that .fail() does not capture all failures;
      # .catch() captures everything, and prevents weird "uncaught (in promise)" Rollbar errors.
      # HOWEVER it seems that Error()s thrown within a catch() may be silently ignored?
      chartRequestFailed = (xhr, textStatus) ->
        doneFunction()

        # Try refreshing the chart in one minute
        foundChart = false
        window.ActiveCharts.forEach (chart) ->
          if chart.selector == element
            wrapUpdateFunction = () ->
              gaEvent('refresh after failure', 'charts', data.chart_type)
              chart.update()

            interval = 1 * 60
            console.log "Refreshing failed chart in", interval, "sec" if debug || debugRefresh

            window.clearTimeout chart.timeout_id if chart.timeout_id
            chart.timeout_id = window.setTimeout wrapUpdateFunction, interval * 1000 # ms
            foundChart = true

        if debug && !foundChart
          throw new Error("Could not find chart selector #{element} in #{window.ActiveCharts.length} active charts")

        if xhr.responseText && xhr.responseText.indexOf('html') != -1 && xhr.responseText.indexOf('login') != -1
          # It's likely we need to login
          return updateError(element, "Session has timed out")

        # if xhr.status = 0, then:
        # - it may be illegal CORS request
        # - it may be blocked by a firewall
        # - the request may be cancelled in code
        # - a browser extension is interrupting
        # - the user is reloading/navigating away
        return if xhr.status == 0

        failure = "#{xhr.status} #{xhr.statusText}"
        info = []
        try
          if xhr.responseJSON
            failure = xhr.responseJSON["message"]
            info = xhr.responseJSON["info"]
            console.log "Successfully parsed responseText" if debug
        catch e
          console.error "Error when parsing responseText", e
          # nothing

        updateError(element, failure, info)
        if failure == "Chart::CannotGetBalancesError" || failure == "Chart::InvalidAccountError"
          # nothing
        else if xhr.status == 0 || !failure
          console.error "Could not load", this_url, failure
        else
          throw new Error("Could not load #{this_url}: #{failure}")

      return unless enableCharts

      $.ajax({
        method:   'GET',
        url:      this_url,
        dataType: 'json',
      }).done((json) ->
        doneFunction()

        foundChart = false
        window.ActiveCharts.forEach (chart) ->
          if chart.selector == element
            # So that we can resize/refresh the chart later
            chart.last_json = json
            chart.refresh = (resizing = false) ->
              console.log "refreshing chart", chart if debug
              console.log "last json = ", chart.last_json if debug
              updateChartOrError(chart, resizing)

            chart.resize = () ->
              chart.refresh(true)

            chart.refresh()
            foundChart = true

            if chart.automaticRefresh
              # Set up a timeout to refresh the chart, based on when the chart thinks
              # it should be updated (if it provided it)
              wrapRefreshFunction = () ->
                gaEvent('automatic refresh', 'charts', data.chart_type)
                chart.update()

              interval = chart.last_json["result"]["interval"] || 60 # sec
              console.log "Refreshing", data.chart_type, "chart in", interval, "sec" if debug || debugRefresh

              window.clearTimeout chart.timeout_id if chart.timeout_id
              chart.timeout_id = window.setTimeout wrapRefreshFunction, interval * 1000 # ms

        if debug && !foundChart
          throw new Error("Could not find chart selector #{element} in #{window.ActiveCharts.length} active charts")

      ).catch(chartRequestFailed)

  window.ActiveCharts.push {
    selector:    element,
    update:      chartIntervalFunction,
    timeout_id:  null,
    data:        data,
    refresh:     null,
    resize:      null,
    hadSuccess:  false,
    automaticRefresh: automaticRefresh,
  }

  # mark it as loading
  displayLoading(element)

  # call it now to load the initial data
  # (once the Google library has been loaded)
  if google.visualization
    console.log "google.visualization is set: rendering now" if debug
    gaEvent('load', 'charts', data.chart_type)
    chartIntervalFunction()
  else
    console.log "google.visualization is not set: using callback to render chart later" if debug
    gaEvent('deferred load', 'charts', data.chart_type)
    google.charts.setOnLoadCallback(chartIntervalFunction)

getVirtualChartUrl = (data) ->
  throw new Error("Need a chart type for a virtual chart") unless data["chart-type"]

  url = "/charts/#{data["chart-type"]}/virtual.json?a=1"

  chartArguments.forEach (key) ->
    if data[key]
      url += "&#{key.replace(/-/g, "_")}=#{data[key]}"

  url += "&now=#{cacheParameter()}"
  url

prepareAdvertisement = (element) ->
  console.log "displaying ad for", element if debug
  linkOptionsMenu(element)

waitingForGoogleToLoadSince = null

prepareCharts = () ->
  polyfillString()

  console.log "preparing charts" if debug

  # if google hasn't been loaded yet, let's wait a bit
  if typeof google == 'undefined'
    telemetryEvent('waiting for google', null)

    timestamp = Math.floor(Date.now() / 1000)
    if waitingForGoogleToLoadSince == null
      waitingForGoogleToLoadSince = timestamp

    if waitingForGoogleToLoadSince < timestamp - 60 # if it hasn't loaded within 60 seconds
      throw new Error("Gave up waiting for google to load since #{waitingForGoogleToLoadSince}")

    console.log "waiting for google to load" if debug
    window.setTimeout prepareCharts, 100 # ms
    return
  else
    # it's been loaded
    waitingForGoogleToLoadSince = null

  # we will want to load charts, but this will be done async
  google.charts.load('current', {'packages':['corechart']})

  garbageCollectCharts()

  showChartWhenOnScreen = (url, element, data) ->
    chart_onscreen = new OnScreen({
      tolerance: -document.documentElement.clientHeight, # render charts before they actually appear on screen
      container: document,
    })
    unique_class = uniqueId(element)

    console.log "unique id is", unique_class if debug
    $(element).addClass unique_class

    chart_onscreen.on 'enter', ".#{unique_class}", (onscreen_element, onscreen_event) ->
      # We need to only register a chart once
      unless $(element).data("chart-enabled")
        $(element).data("chart-enabled", true)
        registerChart(url, element, data)

  turboEach document, "div.chart[data-chart-id]", (element) ->
    console.log "preparing chart for", element if debug

    portfolio_id = $(element).data("portfolio-id")
    chart_id = $(element).data("chart-id")
    chart_type = $(element).data("chart-type")

    if chart_type == "Advertisement"
      return prepareAdvertisement(element)

    data = {
      portfolio_id: portfolio_id,
      chart_id: chart_id,
      chart_type: chart_type, # Not strictly necessary, but useful for Analytics
    }

    url = "/portfolios/#{portfolio_id}/charts/#{chart_id}.json?now=#{cacheParameter()}"
    showChartWhenOnScreen(url, element, data)

  turboEach document, "div.chart[data-public-chart-id]", (element) ->
    console.log "preparing public chart for", element if debug

    public_key = $(element).data("public-key")
    public_chart_id = $(element).data("public-chart-id")
    chart_type = $(element).data("chart-type")

    if chart_type == "Advertisement"
      return prepareAdvertisement(element)

    data = {
      public_key: public_key,
      public_chart_id: public_chart_id,
      chart_type: chart_type, # Not strictly necessary, but useful for Analytics
    }

    url = "/shared/#{public_key}/charts/#{public_chart_id}.json?now=#{cacheParameter()}"
    showChartWhenOnScreen(url, element, data)

  turboEach document, "div.chart[data-virtual-chart]", (element) ->
    console.log "preparing virtual chart for", element if debug

    data = {}
    chartArguments.forEach (key) ->
      data[key] = $(element).data(key)

    data['chart-type'] = $(element).data('virtual-chart')

    if data['chart-type'] == "Advertisement"
      return prepareAdvertisement(element)

    url = getVirtualChartUrl(data)
    showChartWhenOnScreen(url, element, data)

  # For the portfolio page, one of our JS components appears to have a memory leak.
  # This is a TEMPORARY fix to the memory leak issue.
  turboEach document, "div.fullpage-layout.controller-portfolios.action-show", (element) ->
    reloadPage = () ->
      window.location.reload()

    window.setTimeout reloadPage, 2 * 60 * 60 * 1000 # every two hours, reload

lastResizeEvent = null

resizeAllCharts = () ->
  now = new Date().getTime() # msec

  lastResizeEvent = now

  window.ActiveCharts.forEach (chart) ->
    if chart.resize
      chart.resize()

resizeCharts = (e) ->
  now = new Date().getTime() # msec

  if lastResizeEvent == null || lastResizeEvent + resizeDelay <= now
    console.log "resizing charts", e if debug
    resizeAllCharts()

    window.setTimeout resizeAllCharts, resizeDelay * 10 # also issue another resize to catch when the resizing has finished

resetExecutionQueue = () ->
  jsQueue.reset()

# TODO refactor out
telemetryEvent = (label, e) ->
  if typeof Rollbar != "undefined"
    data = {
      event: label,
      url:   document.location.href,
    }

    Rollbar.captureEvent(data, 'info')

$(document).on 'turbolinks:load', prepareCharts
$(document).on 'openclerk:document-updated', prepareCharts
$(document).on 'ready', prepareCharts
$(window).on 'load', prepareCharts

$(document).on 'turbolinks:before-visit', resetExecutionQueue
if enableResize
  $(window).on 'resize', resizeCharts

module.exports = {
  chartArguments: chartArguments,
  getVirtualChartUrl: getVirtualChartUrl,
  registerChart: registerChart,
}
