$ = require('jquery')
turboEach = require('./turbo-each')

qrcode = require('qrcodejs2')

debug = false

apply = (parent) ->
  turboEach parent, "[data-qr-code]", (element) ->
    console.log "generating QR code on", element if debug

    qr = new qrcode(element, element.getAttribute("data-qr-code"))

module.exports = {
  apply: apply,
}
