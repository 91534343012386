$ = require('jquery')
jcarousel = require('jcarousel')
jcarouselSwipe = require('jcarouselSwipe')

turboEach = require('./turbo-each')
lightbox = require('./lightbox')

debug = false
expandCarouselsOnMobile = 600 # px

screenWidth = () ->
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

apply = (parent) ->
  turboEach document, '.carousel', (element) ->
    console.log "carousel on", element if debug

    if $(element).hasClass("mobile-flatten") && screenWidth() <= expandCarouselsOnMobile
      $(element).addClass("not-active")
      $(document).find("[data-carousel-expand]").remove() # hide 'expand' link

    else
      $(element).jcarousel({
        wrap: 'circular'
      })
      $(element).jcarouselSwipe()

      unless $(element).find("button.prev").length != 0       # Turbolinks can get in the way and make duplicate buttons
        $(element).append("<button class='prev' style='display:none;'>Previous</button><button class='next' style='display:none;'>Next</button>")
        $(element).find("button:hidden").fadeIn()

      $(element).find("button.prev").jcarouselControl(target: "-=1")
      $(element).find("button.next").jcarouselControl(target: "+=1")

  turboEach document, '[data-carousel-expand]', (element) ->
    $(element).on 'click', (e) ->
      carousels = $(document).find(".carousel")
      carousels.jcarousel('destroy')
      carousels.addClass("not-active")
      carousels.find("button.prev").remove()
      carousels.find("button.next").remove()
      carousels.children().removeAttr("style")
      carousels.find("*").off("dragstart")
      carousels.off("mousedown")

      $(element).remove() # hide 'expand' link

      lightbox.apply(document)

      e.preventDefault()
      return false

module.exports = {
  apply: apply,
}
