$ = require('jquery')

debug = false

# You can safely call this function multiple times on a page, and it
# will only execute the callback once.
# e.g. you can call this on
# - $(document).ready
# - document.addEventListener 'turbolinks:load'
# - $(document).on 'openclerk:document-updated'
#
# selectorKey is optional
fn = (parent, selector, selectorKey, callback) ->
  if callback == undefined
    callback = selectorKey
    selectorKey = "#{selector}-enabled"

  $(parent).find(selector).each (index, element) ->
    unless $(element).data(selectorKey)
      console.log "Applying", selector, "to", index, ": ", element if debug

      $(element).data(selectorKey, true)
      $(element).attr("data-turbo-each", selectorKey) if debug
      callback(element)

module.exports = fn
