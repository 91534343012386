$ = require('jquery')
escapeHtml = require('escape-html')

capitalize = (string) ->
  string[0].toUpperCase() + string.substring(1)

activityText = (key) ->
  # TODO will need i18n
  switch key
    when "running"
      "Recalculating..."
    when "fetching"
      "Fetching..."
    when "queued"
      "In queue..."
    when "completed"
      "Completed"
    else
      # Originally this was all keys, but there is so much data that we want
      # to provide to the user, it makes more sense to allow english too.
      "#{capitalize(key.split("-").join(" "))}..."

# Data format:
# {
#   label:    'my label',
#   percent:  23,
#   activity: 'fetching',
# }
render = (data) ->
  percent = data['percent'] * 1
  label = if data['activity']
    activityText(data['activity'])
  else
    data['label']

  "<li class=\"progress-bar\">
    <div class=\"bar striped animated no-wrap\" style=\"width:#{percent}%; transition: ease-out 1s;\">
      #{escapeHtml(label)}
    </div>
  </li>"

update = (element, data) ->
  percent = data['percent'] * 1
  label = if data['activity']
    activityText(data['activity'])
  else
    data['label']

  target = $(element).find("li.progress-bar div.bar")

  target.html(escapeHtml(label))
  target.css("width", "#{percent}%")

module.exports = {
  render: render,
  update: update,
}
