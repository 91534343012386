$ = require('jquery')
turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach document, '.closable', (element) ->
    $(element).append("<a class='closable-button' rel='close'></a>")

    $(element).find(".closable-button").on 'click', (e) ->
      $(element).addClass('closing')
      $(element).slideUp(400) # default

module.exports = {
  apply: apply,
}
