# Google Analytics will automatically limit outgoing events to one per second
# "Each gtag.js and analytics.js tracker object starts with 20 hits that are replenished at a rate of 2 hits per second."
# BUT there's a limit of 500 hits per "session", about four minutes.
# So, we limit it to one per five seconds

lastGaEvent = null
limitToEvery = 5 # seconds

gaEvent = (action, category, label = null, value = null) ->
  now = new Date().getTime() / 1000

  if lastGaEvent == null || now > lastGaEvent + limitToEvery
    lastGaEvent = now

    if window.gtag
      window.gtag('event', action, {
        event_category: category,
        # event_action: 'event_name' is the default event_action
        event_label:    label, # e.g. content type
        value:          value,
      })

module.exports = gaEvent
