$ = require('jquery')
turboEach = require('./turbo-each')
tablesorter = require('tablesorter')

debug = false

settings = {
  theme: 'materialize'
}

apply = (parent) ->
  window.tablesorter = tablesorter

  # If we haven't loaded tablesorter yet (???) then
  # try the next time we call apply() to register it...
  if false && $.tablesorter
    turboEach document, 'table.sortable', (element) ->
      $(element).tablesorter settings
  else
    # As per tablesorter JS
    # I'm hoping that by going through require(), rather than $.fn,
    # we'll be able to ensure tablesorter is always correctly executed
    turboEach document, 'table.sortable', (element) ->
      # merge & extend config options
      c = $.extend( true, {}, tablesorter.defaults, settings, tablesorter.instanceMethods )
      # save initial settings
      c.originalSettings = settings
      # create a table from data (build table widget)
      tablesorter.setup element, c

module.exports = {
  apply: apply,
}
