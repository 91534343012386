$ = require('jquery')
charts = require('./charts')

turboEach = require('./core/turbo-each')
sortable = require('./core/sortable')
toggleButtons = require('./core/toggle-buttons')
shiftCheckbox = require('./core/shift-checkbox')
submitOnChange = require('./core/submit-on-change')
qrCode = require('./core/qr-code')

debug = false

previewFieldSelector = (name) ->
  "[data-preview-field='#{name}'][type='hidden'],
  [data-preview-field='#{name}']:visible"

refreshPreview = (element) ->
  console.log "refreshing", element if debug

  unless $(element).is(":visible")
    # (i.e. none of the parent fields will be visible either!)
    console.log "preview dialog is not visible: bailing", element
    return

  # If this element is connected to a filterable, we might need
  # the filtered elements to be displayed before we can accurately
  # display a preview
  filterables = $(element).find(".filterable [data-filter-source]")
  console.log " >> filterables are ", filterables if debug
  filterables.trigger("openclerk:refresh-filters")

  data = {}
  charts.chartArguments.forEach (key) ->
    selector = previewFieldSelector(key)
    if key.startsWith('is-')
      selector += ":checked"

    data[key] = $(element).find(selector).val()

  console.log "preview data:", data if debug

  url = charts.getVirtualChartUrl(data)
  preview = $(element).find(".preview")[0]

  if typeof preview == 'undefined'
    throw new Error("Could not find preview chart in #{element}")

  charts.registerChart(url, preview, data, false)

prepareDocument = () ->
  console.log "preparing secure document" if debug

  # Must first apply toggables for indicators, so that they're hidden before
  # we try to apply previews
  toggleButtons.apply(document)

  turboEach document, '[data-realtime-chart-preview]', (element) ->
    console.log "preparing preview for", element if debug

    $(element).find("input, select").each (_, input) ->
      console.log "change on input", input, "to", element if debug
      $(input).on 'change', (e) ->
        refreshPreview(element)

    refreshPreview(element)

  sortable.apply(document)
  shiftCheckbox.apply(document) # Must be after sortable, in case we have a checkbox in the table
  submitOnChange.apply(document)
  qrCode.apply(document)

$(document).on 'turbolinks:load', prepareDocument
$(document).on 'openclerk:document-updated', prepareDocument
$(document).on 'ready', prepareDocument
$(window).on 'load', prepareDocument

