$ = require('jquery')

turboEach = require('./turbo-each')

debug = false

apply = (parent) ->
  turboEach document, '.accordian', (element) ->
    console.log "accordian on", element if debug

    $(element).find("header").on 'click', (e) ->
      panels = $(element).find("aside")
      panels.each (_, panel) ->
        console.log "checking panel", panel, "for accordian", element if debug

        if panel.style.maxHeight
          $(element).find("header").removeClass("active")
          $(panel).removeClass("active")
          panel.style.maxHeight = null
        else
          $(element).find("header").addClass("active")
          $(panel).addClass("active")
          panel.style.maxHeight = panel.scrollHeight + "px"

      e.preventDefault()
      return false

    if $(element).is(".opened")
      $(element).find("header").trigger("click")
      $(element).addClass("loaded")

module.exports = {
  apply: apply,
}
